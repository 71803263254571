import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2020-02-27T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`Don't force consumers to adopt features they don't need.`}</p>
    </blockquote>
    <p>{`Charles Lee, ReactConf AU`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      